/**
 * CONTENTS
 * --------
 * SCROLL START AT TOP
 * PAGE LOADED CLASS
 * GALLERY RANDOM OVERLAY COLOUR
 * BANNER HEADING ANIMATION
 * SCROLL REVEAL ANIMATION
 * PAGE TRANSITION
 * FORM
 * DROPDOWN MENU
 * MOBILE MENU OVERLAY
 * ACCORDION
 * IFRAME WRAPPER
 * RESPONSIVE/ACCESSIBLE TABLE
 */

/**
* --------------------------------------------------------------------------------------------------------------------
* --------------------------------------------------------------------------------------------------------------------
* SCROLL START AT TOP
* --------------------------------------------------------------------------------------------------------------------
* --------------------------------------------------------------------------------------------------------------------
*/

// removed as tel and mailto links broke this

// $(window).on('beforeunload', function () {
// 	$('body').hide();
// 	$(window).scrollTop(0);
// });

$(document).ready(function () {

	/**
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	* PAGE LOADED CLASS
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	*/

	$('body').addClass('js-page-loaded');

	/**
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	* GALLERY RANDOM OVERLAY COLOUR
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	*/

	// applies a random class modifier, styles added in _gallery.scss

	if ($('.js-gallery-random-overlay').length > 0) {

		var overlayClassModifiers = [
			'cyan',
			'magenta',
			'yellow',
			'black'
		];

		$('.c-gallery__grid-item').on('mouseenter', function () {

			var idx = Math.floor(Math.random() * overlayClassModifiers.length);

			$(this).find('.c-gallery__overlay').removeClass(function (index, css) {
				return (css.match(/\bc-gallery__overlay--\S+/g) || []).join(''); // removes any class that starts with "c-gallery__overlay--"
			});

			$(this).find('.c-gallery__overlay').addClass('c-gallery__overlay--' + overlayClassModifiers[idx]);

		});

	}

	/**
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	* BANNER HEADING ANIMATION
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	*/

	// requires; TweenMax, TimeLineLite, SplitText

	/* if ($('.js-gsap-text-reveal').length > 0) {

		if (window.matchMedia('screen and (min-width: 768px)').matches) {

			var splitLines = new SplitText('.js-gsap-text-reveal', {
				type: "lines",
				linesClass: "line line++"
			});


			$('.js-gsap-text-reveal .line').wrap('<div class="c-gsap-text-reveal-wrapper">');

			TweenMax.staggerFrom(
				splitLines.lines,
				0.5,
				{
					y: '100%',
					ease: Linear.easeInOut
				},
				0,
				splitRevert
			);

			function splitRevert() {
				$('.js-gsap-text-reveal .line').unwrap();
				splitLines.revert()
			}

		}

	} */

	/**
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	* SCROLL REVEAL ANIMATION
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	*/

	// for modular element items, e.g. grids, blog listing
	var animatedItems = document.querySelectorAll('.js-gsap-fade-up-animation');
	
	var config = {
		threshold: 0.15, // how "in view" should the element be, threshold number taken from testing
	};

	var tl1 = new TimelineLite();

	var observer = new IntersectionObserver(function (entries, self) {
		entries.forEach(function (entry) {
			if (entry.isIntersecting) {
				var overlap = '-=0.375';

				if (!tl1.isActive()) {
					overlap = '+=0';
				}

				tl1.fromTo(entry.target, 0.1, { css: { opacity: 0 } }, { css: { opacity: 1 } }, overlap)
					.fromTo(entry.target, 0.5, { css: { top: "50px" } }, { css: { top: "0px" } }, overlap);

				self.unobserve(entry.target);
				
			}
		});
	}, config);

	animatedItems.forEach(function (item) {
		observer.observe(item);
	});

	//
	$('.js-gsap-fade-up-animation-instant').each(function() {

		var tl2 = new TimelineLite();

		var delayTime = 0;

		if ($(this).hasClass('js-gsap-fade-up-animation-instant-body')) {

			delayTime = 0.5;

		}

		tl2.fromTo($(this), 0.1, { css: { opacity: 0 } }, { css: { opacity: 1 } }, 0)
			.fromTo($(this), 0.5, { css: { top: "50px" } }, { css: { top: "0px" } }, 0);
		tl2.delay(delayTime);

	});

	/**
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	* PAGE TRANSITION
	* --------------------------------------------------------------------------------------------------------------------
	* --------------------------------------------------------------------------------------------------------------------
	*/

	fadeInPage();

	function fadeInPage() {

		if (!window.AnimationEvent) { return; }

		var fader = document.getElementById('js-page-transition');
		fader.classList.add('fade-out');

	}

	document.addEventListener('domcontentloaded', function () {

		if (!window.animationevent) { return; }

		var anchors = document.getelementsbytagname('a');

		console.log(anchors);

		for (var idx = 0; idx < anchors.length; idx += 1) {

			if (anchors[idx].hostname !== window.location.hostname) {
				continue;
			}

			anchors[idx].addEventListener('click', function (event) {
				var fader = document.getelementbyid('js-page-transition');
				var anchor = event.currenttarget;

				var listener = function () {
					window.location = anchor.href;
					fader.removeeventlistener('animationend', listener);
				};

				fader.addEventListener('animationend', listener);

				event.preventdefault();

				fader.classlist.add('fade-in');

			});

		}

	});

	window.addEventListener('pageshow', function (event) {

		if (!event.persisted) {
			return;
		}

		var fader = document.getElementById('js-page-transition');
		fader.classList.remove('fade-in');

	});

	/**
  * --------------------------------------------------------------------------------------------------------------------
  * --------------------------------------------------------------------------------------------------------------------
  * FORM
  * --------------------------------------------------------------------------------------------------------------------
  * --------------------------------------------------------------------------------------------------------------------
  */

	if ($('.js-form-validate').length > 0) {

		// initialise parsley
		$('.js-form-validate').parsley();

		// if the form is invalid change aria live attribute on the first error warning
		$('.js-form-validate').each(function () {

			$(this).parsley().on('field:error', function () {

				$(this).find('.parsley-errors-list li').eq(0).attr('aria-live', 'assertive');

			});

		});

	}

	// radio select
	$('.js-form-radio-input').on('change', function (e) {

		$('#' + $(this).closest('div').attr('id') + ' .c-form__radio-choice-select-wrapper').addClass('c-form__radio-choice-select-wrapper--hidden');
		$('.c-form__radio-choice-select-wrapper[data-radio-choice=' + $(this).attr('id') + ']').removeClass('c-form__radio-choice-select-wrapper--hidden');

		$('.c-form__radio-choice-select-wrapper[data-radio-choice=' + $(this).attr('id') + ']').find('select').attr('required', true).attr('data-parsley-required-message', 'Please choose an option');

	});

	// option select
	$('.js-form-select').on('change', function (e) {

		$(".c-quote-form").show();

		var selectedFilter = $(this).find('.js-form-option:selected').data('selected-filter');

		$('.c-form__selected-filter-content').removeClass('c-form__selected-filter-content--active');

		$('.c-form__selected-filter-content[data-selected-filter-choice=' + selectedFilter + ']').addClass('c-form__selected-filter-content--active');

	});

	// form selection
	$('.c-quote-signpost-form').first().on('submit', function (e) {
		location.href = $(this).find('select[name="project-form"]').val();
		e.preventDefault();
	});

	if ($('.c-quote-form-success-message').length > 0) {
		$('html, body').animate({
			scrollTop: $('.c-quote-form-success-message').offset().top - 200
		}, 750);
		$(".c-quote-form").hide();
	}

	// Display file name to user
	$("#fileUpload").change(function () {
		$("#file-name").text(this.files[0].name);
	});

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * DROPDOWN MENU
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	$('.js-dropdown-menu-control').on('click', function (e) {

		// remove active class from sibling links
		$(this).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu-control').removeClass('js-dropdown-menu-control-active');

		// toggle active link class
		$(this).toggleClass('js-dropdown-menu-control-active');

		// remove open class from all sibling dropdown menus
		$(this).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu').removeClass('c-dropdown-menu--open');

		// toggle open class
		$(this).next('.js-dropdown-menu').toggleClass('c-dropdown-menu--open');

		// change aria hidden of all sibling dropdown menus to true
		$(this).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu').attr('aria-hidden', 'true');

		// change aria hidden to false
		$(this).next('.js-dropdown-menu').attr('aria-hidden', 'false');

		// change aria hidden to true when menu not visible
		if (!$(this).next('.js-dropdown-menu').hasClass('c-dropdown-menu--open')) {

			$(this).next('.js-dropdown-menu').attr('aria-hidden', 'true');

		}

		// stop default action of link
		// exclude dropdown menu from click event that will remove active and open classes, see below
		e.preventDefault();
		e.stopPropagation();

		// exclude dropdown menu from click event that will remove active and open classes, see below
		$('.js-dropdown-menu').on('click', function (e) {

			e.stopPropagation();

		});

		// caal closeDesktopDropdownMenu function on clicking on body when dropdown is open
		$('body').on('click', function () {

			closeDesktopDropdownMenu();

		});

	});

	// remove active and open classes from all dropdown menus when clicking on the body
	// change aria hidden to true when clicking outside dropdown menu
	function closeDesktopDropdownMenu() {

		$('.js-dropdown-menu-control').removeClass('js-dropdown-menu-control-active');

		$('.c-primary-nav__link').removeClass('js-dropdown-menu-control-active');

		$('.js-dropdown-menu').removeClass('c-dropdown-menu--open');

		$('.js-dropdown-menu').attr('aria-hidden', 'true');

	}

	// detect when nav link is focused on using the tab key
	$(window).keyup(function (e) {
		var code = (e.keyCode ? e.keyCode : e.which);
		if (code == 9 && $('.c-primary-nav__link:focus').length) {

			// remove active class from sibling links
			$(e.target).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu-control').removeClass('js-dropdown-menu-control-active');

			$(e.target).closest('.c-primary-nav__item').siblings().find('.c-primary-nav__link').removeClass('js-dropdown-menu-control-active');

			// toggle active link class
			$(e.target).addClass('js-dropdown-menu-control-active');

			// remove open class from all sibling dropdown menus
			$(e.target).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu').removeClass('c-dropdown-menu--open');

			// toggle open class
			$(e.target).next('.js-dropdown-menu').toggleClass('c-dropdown-menu--open');

		}
	});

	// remove active class from primary nav links when focused out of nav
	$('.c-primary-nav__item:last-child').on('focusout', function () {

		$('.c-primary-nav__link').removeClass('js-dropdown-menu-control-active');

	});

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * MOBILE MENU OVERLAY
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	$('.js-open-mobile-nav-overlay').on('click', function (e) {

		// add open class
		$('.js-mobile-nav-overlay').addClass('c-mobile-nav-overlay--open');

		// remove and add state classes
		$('.c-mobile-nav-overlay-open-control').removeClass('c-mobile-nav-overlay-open-control--active').addClass('c-mobile-nav-overlay-open-control--inactive');
		$('.c-mobile-nav-overlay-close-control').removeClass('c-mobile-nav-overlay-close-control--inactive').addClass('c-mobile-nav-overlay-close-control--active');

		// add scroll lock class to body
		$('body').addClass('scroll-lock');

		e.preventDefault();
		e.stopPropagation();

	});

	// call closeMobileOverlay function
	$('.js-close-mobile-nav-overlay').on('click', function (e) {

		closeMobileOverlay(e);

	});

	// remove open class, blur mobile search form, and remove scroll lock
	function closeMobileOverlay(e) {

		$('.js-mobile-nav-overlay').removeClass('c-mobile-nav-overlay--open');

		// remove and add state classes
		$('.c-mobile-nav-overlay-open-control').removeClass('c-mobile-nav-overlay-open-control--inactive').addClass('c-mobile-nav-overlay-open-control--active');
		$('.c-mobile-nav-overlay-close-control').removeClass('c-mobile-nav-overlay-close-control--active').addClass('c-mobile-nav-overlay-close-control--inactive');

		$('body').removeClass('scroll-lock');

		// close any open accordions
		setTimeout(function () {
			$('.js-accordion-control').removeClass('js-accordion-control-active');
			$('.js-accordion-content').slideUp();
			$('.js-mobile-nav-overlay').scrollTop(0);
		}, 300);

		e.preventDefault();
		e.stopPropagation();

	}

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * ACCORDION
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	$('.js-accordion-control').on('click', function (e) {

		// note - uncomment next 3 lines to only open one accoridon item at a time
		$(this).closest('.js-accordion-container').siblings().find('.js-accordion-content').slideUp();
		$(this).closest('.js-accordion-container').siblings().find('.js-accordion-control').removeClass('js-accordion-control-active');
		$(this).closest('.js-accordion-container').siblings().find('.js-accordion-control').attr('aria-expanded', 'false');

		// toggle accordion content
		$(this).next('.js-accordion-content').slideToggle();

		// toggle aria expanded value
		$(this).attr('aria-expanded', $(this).attr('aria-expanded') == 'false' ? 'true' : 'false');

		// toggle active class
		$(this).toggleClass('js-accordion-control-active');

		if ($(this).attr('aria-expanded') === 'true') {

			// note - uncomment next line to only open one accoridon item at a time
			$(this).closest('.js-accordion-container').siblings().find('.js-accordion-content').attr('aria-hidden', 'true');

			// if open change aria hidden value to false
			$(this).next('.js-accordion-content').attr('aria-hidden', 'false');

		}

		else {

			// if closed change aria hidden value to true
			$(this).next('.js-accordion-content').attr('aria-hidden', 'true');

		}

		e.preventDefault();
		e.stopPropagation();

	});

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * IFRAME WRAPPER
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	// wrap iframes (found within specific container) in a class to apply styles to maintain a responsive aspect ratio
	$('.js-main-content iframe').each(function () {

		$(this).wrap('<span class="c-iframe-wrapper"></span>');

	});

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * RESPONSIVE/ACCESSIBLE TABLE
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	// check if table exists
	function addMobileAccessibility() {

		if ($('.js-main-content table').length > 0) {

			// each table
			$('.js-main-content table').each(function () {

				// empty array for storing table heading content
				var tableHeadingArray = [];

				// each table heading
				$(this).find('thead tr th').each(function () {

					// push text string from each table heading to array
					tableHeadingArray.push($(this).text());

				});

				// loop for as many items as are in the array
				for (var i = 0; i < tableHeadingArray.length; i++) {

					// add aria label attribute with table heading array values to tds wrapping td content in span
					$(this).find('td:nth-child(' + (i + 1) + ')').attr({
						'aria-label': tableHeadingArray[i],
						'scope': 'row'
					}).wrapInner('<span class="c-td__span"></span>');

				}

			});

		}

	}

	function removeMobileAccessibility() {

		// remove span when resizing larger than breakpoint
		$('.js-main-content table td .c-td__span').contents().unwrap();

		// remove aria lable and scope attributes - not needed in desktop layout
		$('.js-main-content table td').removeAttr('aria-label').removeAttr('scope');

	}

	(function (window, document, undefined) {
		'use strict';

		// initialize the media query
		var mediaQuery = window.matchMedia('(min-width: 576px)');

		// add a listen event
		mediaQuery.addListener(responsiveTable);

		// function to do something with the media query
		function responsiveTable(mediaQuery) {

			if (mediaQuery.matches) {
				removeMobileAccessibility();
			}

			else {
				addMobileAccessibility();
			}

		}

		// on load
		responsiveTable(mediaQuery);

	})(window, document);

});
